import React, { Component, Fragment } from "react";
import axios from "axios";
// import { Button, Form } from "react-bootstrap";
import Helper from "../Global/Helper";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import ModalAlert from "../Components/ModalAlert";
import liffHelper from "../utils/liffHelper";
import messageHelper from "../utils/messagingApiHelper";
import logo_head from "./images/logo.png";
import appbanner from "./images/appbanner.png";

import Service from "../services/home";
import exit from "./images/exit.png";
import closepop from "./images/closepop.png";
import check from "./images/check.png";
import { geolocated } from "react-geolocated";

const liff = window.liff;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "Home",
      openCode: false,
      openPolicy: false,
      accept_policy: false,
      isRegistered: false,
      checkAcceptPolicy: false,
      submit_btn: true,
      code: "",
      first_name: "",
      jwt: null,
      last_name: "",
      mobile: "",
      isErrorCode: "",
      disableButton: true,
      msg: "",
      master_campaign_id: "19",
      policy_name: "2tm_policy",
      profile: {
        userId: "",
      },
      otp_number: "",
      disableOTPButton: true,
      isReQuestError: false,
      isVerifySuccess: false,
      isReQuestError_msg: "",
      data: {},
      otp_code: "",
      isReQuestError_head: "",
      ref_code: "",
      location: null,
      msg_error: "รหัส OTP ไม่ถูกต้อง",
      minutes: 0,
      seconds: 5,
    };
  }

  componentDidMount() {
    this.actionStart();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          location: position.coords.latitude + "," + position.coords.longitude,
        });
      },
      (error) => console.log(error)
    );
  }
  // ---------------------------SERVICE--------------------
  // LOAD JWT
  async actionStart() {
    try {
      const { data, status } = await Service.loadStartJWT();
      if (status === "Success") {
        if (data !== null) {
          this.setState({
            jwt: data,
          });
        } else {
          console.log("jwt not found");
          liff.closeWindow();
        }
      } else {
        liff.closeWindow();
        console.log("jwt NOT SUCCESS");
      }
    } catch (error) {
      liff.closeWindow();
      console.log("DISCONNECT API");
    }
  }

  // ---------------------------END SERVICE--------------------

  submitData() {
    this.insertDataCustomer();
  }
  async insertDataCustomer() {
    const { history } = this.props;
    const { first_name, location, mobile, last_name, jwt } = this.state;
    let phone_number = mobile || "";
    let email = "";
    let locations = location || [0, 0];
    let promotion_code = "";
    let master_campaign_id = "19";
    let obj_location_detail = "";
    let otp_code = "";
    let otp_ref_code = "";
    let uidLine = this.state.profile.userId || "";
    try {
      const { status, msg, data } = await Service.insertDataCustomer(
        jwt,
        first_name,
        last_name,
        phone_number,
        email,
        locations,
        promotion_code,
        master_campaign_id,
        obj_location_detail,
        otp_code,
        otp_ref_code,
        uidLine
      );
      if (status === "Success") {
        const { exp } = data;
        this.onVerifySuccess(exp);
      } else {
        this.setState({
          isRegistered: true,
          msg_error: msg,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  onVerifySuccess = (exp) => {
    this.setState(
      {
        isVerifySuccess: true,
      },
      () => {
        this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state;

          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            clearInterval(this.myInterval);
            console.log("Success Send Coupon");
          }
        }, 1000);
        setTimeout(() => {
          this.sendEasyMoneyPromotion(exp);
        }, 5000);
        setTimeout(() => {
          liff.closeWindow();
        }, 5500);
      }
    );
  };
  sendEasyMoney(exp) {
    liff.sendMessages([
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://2tm.easymoneydev.com/images/2TM_1.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            spacing: "md",
            contents: [
              {
                type: "text",
                text: "หมดอายุ วันที่ 30 กันยายน 2563",
                size: "xs",
                align: "start",
                gravity: "top",
                weight: "bold",
                color: "#000000",
                wrap: false,
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "เงื่อนไขการใช้คูปอง",
                    size: "xxs",
                    weight: "bold",
                    color: "#000000",
                  },
                  {
                    type: "separator",
                    margin: "sm",
                  },
                  {
                    type: "text",
                    text:
                      "- หมายเลขโทรศัพท์ต้องตรงกับที่ระบุบนตั๋วจำนำเท่านั้น",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้เพียงครั้งเดียวเท่านั้น ใช้เป็นส่วนลด",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "ดอกเบี้ยเฉพาะที่สาขาเท่านั้น ",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้ตั้งแต่วันที่ 16 มิถุนายน 2563",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "จนถึงวันที่ 30 กันยายน 2563",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ไม่สามารถแลกเปลี่ยนเป็นเงินสดได้",
                    size: "xxs",
                  },

                  {
                    type: "text",
                    text: "- สามารถใช้ได้เฉพาะตั๋วจำนำที่จำนำในเดือนมิถุนายน-",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "กันยายน 2563 เท่านั้น",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ไม่สามารถใช่ได้กรณีไถ่ถอน",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "(เมื่อครบวันหมดอายุไม่สามารถใช้งานได้)",
                    size: "xxs",
                  },
                ],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://2tm.easymoneydev.com/images/2TM_150.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            spacing: "md",
            contents: [
              {
                type: "text",
                text: "หมดอายุ วันที่ 30 กันยายน 2563",
                size: "xs",
                align: "start",
                gravity: "top",
                weight: "bold",
                color: "#000000",
                wrap: false,
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "เงื่อนไขการใช้คูปอง",
                    size: "xxs",
                    weight: "bold",
                    color: "#000000",
                  },
                  {
                    type: "separator",
                    margin: "sm",
                  },
                  {
                    type: "text",
                    text: "- ใช้เป็นส่วนลดทองคำสภาพใหม่ ",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "ณ โรงรับจำนำอีซี่มันนี่ ทุกสาขา",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ใช้เป็นส่วนลดในการซื้อหน้าร้านและออนไลน์",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้ถึงวันที่ 30 กันยายน 2563",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "(สามารถใช้ได้มากกว่า 1 ครั้ง / เมื่อครบวันหมดอายุ",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "ไม่สามารถใช้งานได้)",
                    size: "xxs",
                  },
                ],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://2tm.easymoneydev.com/images/2TM_3.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            spacing: "md",
            contents: [
              {
                type: "text",
                text: "หมดอายุ วันที่ " + exp,
                size: "xs",
                align: "start",
                gravity: "top",
                weight: "bold",
                color: "#000000",
                wrap: false,
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "เงื่อนไขการใช้คูปอง",
                    size: "xxs",
                    weight: "bold",
                    color: "#000000",
                  },
                  {
                    type: "separator",
                    margin: "sm",
                  },
                  {
                    type: "text",
                    text: "- ใช้เป็นส่วนลดเครื่องประดับเพชร-พลอย",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ใช้เป็นส่วนลดในการซื้อหน้าร้านและออนไลน์",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้ถึงวันที่ " + exp,
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "(สามารถใช้ได้มากกว่า 1 ครั้ง / เมื่อครบวันหมดอายุ",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "ไม่สามารถใช้งานได้)",
                    size: "xxs",
                  },
                ],
              },
            ],
          },
        },
      },
    ]);
  }
  sendEasyMoneyNoPolicy() {
    liff.sendMessages([
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://easymoneydev.com/newphetchaburi/coupon1.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://easymoneydev.com/newphetchaburi/coupon2.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://easymoneydev.com/newphetchaburi/coupon3.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
        },
      },
    ]);
  }
  sendEasyMoneyPromotion(exp) {
    liff.sendMessages([
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url:
              "https://tungthanasin.co.th/NewCustomerLineliff/image_coupon/Ads%20Landing%20Page2-02.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            spacing: "md",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "เงื่อนไขการใช้คูปอง",
                    size: "xxs",
                    weight: "bold",
                    color: "#000000",
                  },
                  {
                    type: "separator",
                    margin: "sm",
                  },
                  {
                    type: "text",
                    text:
                      "- เมื่อซื้อสินค้ามูลค่า 5,000 บาทขึ้นไป ที่โรงรับจำนำ",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "  อีซี่มันนี่ ทุกสาขา",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ไม่สามารถแลกหรือเปลี่ยนเป็นเงินสดได้",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้ 1 ครั้ง/1 ธุรกรรม/1 สิทธิ์",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- กรุณาใช้สิทธิ์ภายใน 1 เดือน หลังจากลงทะเบียน",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- เงื่อนไขเป็นไปตามที่บริษัทกำหนด",
                    size: "xxs",
                  },
                ],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url:
              "https://tungthanasin.co.th/NewCustomerLineliff/image_coupon/Ads%20Landing%20Page2-04.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            spacing: "md",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "เงื่อนไขการใช้คูปอง",
                    size: "xxs",
                    weight: "bold",
                    color: "#000000",
                  },
                  {
                    type: "separator",
                    margin: "sm",
                  },
                  {
                    type: "text",
                    text: "- ใช้สิทธิได้ที่โรงรับจำนำอีซี่มันนี่ ทุกสาขา",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ไม่สามารถแลกหรือเปลี่ยนเป็นเงินสดได้",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้ 1 ครั้ง/1 ธุรกรรม/1 สิทธิ์",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- กรุณาใช้สิทธิ์ภายใน 1 เดือน หลังจากลงทะเบียน",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- เงื่อนไขเป็นไปตามที่บริษัทกำหนด",
                    size: "xxs",
                  },
                ],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url:
              "https://tungthanasin.co.th/NewCustomerLineliff/image_coupon/Ads%20Landing%20Page2-03.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            spacing: "md",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "เงื่อนไขการใช้คูปอง",
                    size: "xxs",
                    weight: "bold",
                    color: "#000000",
                  },
                  {
                    type: "separator",
                    margin: "sm",
                  },
                  {
                    type: "text",
                    text:
                      "- เมื่อซื้อทองคำสภาพใหม่ ฟรีค่ากำเหน็จ ที่โรงรับจำนำ",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "  อีซี่มันนี่ ทุกสาขา",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ไม่สามารถแลกหรือเปลี่ยนเป็นเงินสดได้",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้ 1 ครั้ง/1 ธุรกรรม/1 สิทธิ์",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- กรุณาใช้สิทธิ์ภายใน 1 เดือน หลังจากลงทะเบียน",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- เงื่อนไขเป็นไปตามที่บริษัทกำหนด",
                    size: "xxs",
                  },
                ],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url:
              "https://tungthanasin.co.th/NewCustomerLineliff/image_coupon/Ads%20Landing%20Page2-01.jpg",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            spacing: "md",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "text",
                    text: "เงื่อนไขการใช้คูปอง",
                    size: "xxs",
                    weight: "bold",
                    color: "#000000",
                  },
                  {
                    type: "separator",
                    margin: "sm",
                  },
                  {
                    type: "text",
                    text: "- ใช้สิทธิได้ที่โรงรับจำนำอีซี่มันนี่ ทุกสาขา",
                    margin: "sm",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- สามารถใช้ได้ 1 ครั้ง/1 ธุรกรรม/1 สิทธิ์",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- กรุณาใช้สิทธิ์ภายใน 1 เดือน หลังจากลงทะเบียน",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- ไม่สามารถแลกหรือเปลี่ยนเป็นเงินสดได้",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "- เงื่อนไขเป็นไปตามที่บริษัทกำหนด",
                    size: "xxs",
                  },
                ],
              },
            ],
          },
        },
      },
    ]);
  }

  render() {
    const {
      page,
      openPolicy,
      openModalAlert,
      textAlert,
      textAlertSub,
      first_name,
      last_name,
      mobile,
      code,
      submit_btn,
      isErrorCode,
      disableButton,
      profile,
      msg,
      checkAcceptPolicy,
      jwt,
      otp_number,
      disableOTPButton,
      location,
      msg_error,
      isReQuestError,
      isReQuestError_msg,
      isVerifySuccess,
      minutes,
      ref_code,
      seconds,
      isReQuestError_head,
      accept_policy,
      isRegistered,
    } = this.state;
    return (
      <Fragment>
        <body>
          <header>
            <img src={logo_head} alt="" />
          </header>
          <section>
            {isRegistered === true ? (
              <div className="samllpop">
                <div className="samllpop_pop">
                  <img
                    src={exit}
                    onClick={() => {
                      liff.closeWindow();
                    }}
                    alt=""
                    className="exit"
                  />
                  <h2 className="font55">ขออภัยค่ะ</h2>
                  <h3 className="font45">{msg_error}</h3>
                </div>
              </div>
            ) : null}
            <div className="dataform">
              <div className="form-group">
                <span className="group-name">ชื่อ</span>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={this.state.first_name}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[!$%^*()+></?=\;#]/g,
                      ""
                    );
                    if (
                      replaceInput !== "" &&
                      last_name.length > 0 &&
                      mobile.length === 10 &&
                      accept_policy === true
                    ) {
                      this.setState({
                        first_name: replaceInput,
                        submit_btn: false,
                      });
                    } else {
                      this.setState({
                        first_name: replaceInput,
                        submit_btn: true,
                      });
                    }
                  }}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <span className="group-name">นามสกุล</span>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="form-control"
                  value={this.state.last_name}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[!$%^*()+></?=\;#]/g,
                      ""
                    );
                    if (
                      replaceInput !== "" &&
                      first_name.length > 0 &&
                      mobile.length === 10 &&
                      accept_policy === true
                    ) {
                      this.setState({
                        last_name: replaceInput,
                        submit_btn: false,
                      });
                    } else {
                      this.setState({
                        last_name: replaceInput,
                        submit_btn: true,
                      });
                    }
                  }}
                />
              </div>
              <div className="form-group mb0">
                <span className="group-name">เบอร์โทรศัพท์</span>
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  maxLength="10"
                  className="form-control"
                  value={this.state.mobile}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    if (
                      last_name.length > 0 &&
                      first_name.length > 0 &&
                      replaceInput.length === 10 &&
                      accept_policy === true
                    ) {
                      this.setState({
                        mobile: replaceInput,
                        submit_btn: false,
                      });
                    } else {
                      this.setState({
                        mobile: replaceInput,
                        submit_btn: true,
                      });
                    }
                  }}
                />
              </div>
                <p className="form-note">*กรุณางดใช้ระบบพิมพ์ข้อความอัตโนมัติ</p>
              
            </div>
            <div className="btncover">
              <div className="btncover_term">
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    this.setState({
                      openPolicy: true,
                    });
                  }}
                  className="openterm"
                >
                  ข้อกำหนดและเงื่อนไข
                </a>
                <div></div>
                <div className="btncover_term-check">
                  <input
                    type="checkbox"
                    // checked={accept_policy}
                    onClick={() => {
                      if (accept_policy !== true) {
                        if (
                          last_name.length > 0 &&
                          first_name.length > 0 &&
                          mobile.length === 10
                        ) {
                          this.setState({
                            accept_policy: true,
                            submit_btn: false,
                          });
                        } else {
                          this.setState({
                            accept_policy: true,
                            submit_btn: true,
                          });
                        }
                      } else {
                        this.setState({
                          accept_policy: false,
                          submit_btn: true,
                        });
                      }
                    }}
                    name=""
                    id="accept_term"
                  />
                  {accept_policy === true ? (
                    <img
                      src={check}
                      alt=""
                      onClick={() => {
                        if (accept_policy !== true) {
                          if (
                            last_name.length > 0 &&
                            first_name.length > 0 &&
                            mobile.length === 10
                          ) {
                            this.setState({
                              accept_policy: true,
                              submit_btn: false,
                            });
                          } else {
                            this.setState({
                              accept_policy: true,
                              submit_btn: true,
                            });
                          }
                        } else {
                          this.setState({
                            accept_policy: false,
                            submit_btn: true,
                          });
                        }
                      }}
                    />
                  ) : null}
                </div>
                <label id="accept_term" className="checkterm">
                  ยอมรับข้อกำหนดและเงื่อนไข
                </label>
              </div>
              <div></div>
              <button
                className="btn senddata"
                disabled={submit_btn}
                onClick={() => {
                  this.submitData();
                }}
              >
                ยืนยันข้อมูล
              </button>
            </div>
            <div className="appqr">
              <img src={appbanner} alt="" />
            </div>
          </section>
          <footer></footer>
          {openPolicy === true ? (
            <div className="trempop">
              <div className="trempop_pop">
                <img
                  src={closepop}
                  onClick={() => {
                    this.setState({
                      openPolicy: false,
                    });
                  }}
                  alt=""
                  className="closepop"
                />
                <h2 className="font45">ข้อกำหนดและเงื่อนไข</h2>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; อีซี่มันนี่
                  จะขอใช้ข้อมูลที่ระบุไว้ เพื่อวัตถุประสงค์ในการ
                  ป้องกันการใช้งานที่ไม่เหมาะสม รวมไปถึงการใช้บริการ การ
                  พัฒนานำเสนอโปรโมชั่น การประชาสัมพันธ์ และการปรับปรุง
                  การบริการเป็นการเก็บรวบรวมการใช้ และเปิดเผยข้อมูลส่วน
                  ตัวสำหรับข้อมูลต่างๆ ได้แก่ ชื่อ, นามสกุล, เบอร์โทรศัพท์เพื่อ
                  ตรวจสอบ และยืนยันตัวตนในการสมัคร และ/หรือใช้บริการ
                  และ/หรือเป็นหลักฐานในทางกฎหมายสำหรับ ข้อต่อสู้ทาง กฎหมาย
                  หรือเป็นการดำเนินการตามที่กฎหมายกำหนด
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ
                  จะไม่จัดสรรหรือขายข้อมูลส่วนบุคคลของท่านให้
                  แก่บุคคลภายนอกอื่นเพื่อวัตถุประสงค์ในการนำไปใช้โดยอิสระ
                  นอกจากนี้บริษัทฯ จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่
                  องค์กรอื่นใด เว้นแต่การเปิดเผยข้อมูลนั้นเป็นไปตามที่กฎหมาย
                  อนุญาตหรือในกรณีที่บริษัทฯ มีหน้าที่ตามกฎหมายกำหนดให้
                  ต้องเปิดเผย, หรือเป็นการเปิดเผยตามคำสั่งโดยชอบด้วย
                  กฎหมายของศาลหรือพนักงานเจ้าหน้าที่ผู้มีอำนาจ
                  <br />
                  <br />
                  หมายเหตุ
                  <br />
                  1. เมื่อลงทะเบียนสำเร็จ
                  ระบบจะยืนยันให้ทราบทันทีว่าท่านได้ลงทะเบียนเรียบร้อยแล้ว
                  <br />
                  2. ท่านจะได้รับคูปองในไลน์ภายหลังจากการลงทะเบียนเสร็จสิ้น
                  <br />
                  3. กรณีมีปัญหาในการลงทะเบียน หรือสอบถามข้อมูลเพิ่มเติม ติดต่อ
                  Call Center โทร.02-113-1123
                </p>
              </div>
            </div>
          ) : null}
          {isVerifySuccess === true ? (
            <div className="samllpop ">
              <div className="samllpop_pop">
                <h2 className="font55">คุณได้รับสิทธิ์แล้ว</h2>
                <h3 className="font55">ขอบคุณที่ใช้บริการ</h3>
                <span className="font45">
                  ระบบจะนำท่านกลับสู่หน้าแรกอัตโนมัติ{" "}
                  <i id="redirect">{seconds} </i>
                </span>
              </div>
            </div>
          ) : null}
        </body>
      </Fragment>
    );
  }
}
// export default Home;
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Home);
